import React, { useEffect, useState, useRef } from "react";
import { getAllPaymentApi, getExpectedPaymentsStatsByDateApi, getExpectedPaymentsListByDateApi, getRecievedPaymentsStatsByDateApi, getRecievedPaymentsListByDateApi, getRecievedPaymentsStatsByPaymentBucket, getRecievedPaymentsListByPaymentBucket,getOverDueDetailsStatsByDueDate,getOverdueDetailDataByDueDate } from "../AdminActionCreator.component";
import Pagination from '@mui/material/Pagination';
import Loader from '../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../images/nodata.png';
import moment from "moment";
import EmiBreakUp from "./breakdownpopup";
import PopUp from "../../../presentationals/Popup/Popup.component";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { APIS } from "../../../../utils/api-factory";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const handleHeadingText = (name,startDate,endDate)=>{
    const start = moment(startDate).format('DD-MM-YYYY')
    const end = moment(endDate).format('DD-MM-YYYY')

    if(name === "OverduestartDate"){
        return `Overdue Stats till ${start}`
    }else if(name === "OverdueEndDate"){
        return `Overdue Stats till ${end}`
    }else{
        return name
    }
}

const formatDateFunction = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const AllPaymentsNewTab = ({ admin }) => {
    const topRef = useRef(null);
    const bottomRef = useRef(null);

    const [startDate, setStartDate] = useState(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [applicationId, setApplicationId] = useState('');
    const [processType, setProcessType] = useState('');
    const [mode, setMode] = useState('');
    const [objectData, setObjectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [loaderState, setLoader] = useState(false);
    const [payData, setpayData] = useState('');
    const [showData, setshowData] = useState('');
    const [leadData, setLeadData] = useState('');
    const [popupStatus, setpopupStatus] = useState('No Data Found!');
    const [popupState, setpopupState] = useState(false);
    const [countData, setCounts] = useState('');
    const [tableView, setTableView] = useState('')
    const [nbfc, setNbfc] = useState('');
    const [nbfclist, setnbfcList] = useState([]);
    const [initalValue, setInitalValue] = useState({})
    const [totalItems, setTotalItems] = useState({})
    const [filterDataDsec, setfilterDataDsec] = useState("asc")

    const getNbfcList = () => {
        fetch(APIS.GET_LIST_OF_NBFC)
            .then(res => res.json())
            .then(res => {
                setnbfcList(res)
            })
    }


    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 5000);
    }
    const closePopup = () => {
        setshowData(false)
        setpopupState(false)
    }
    useEffect(() => {
        getNbfcList()
        handleApply(startDate, endDate)


    }, []);



    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        selecteTable(value, tableView, filterDataDsec, "pagination")
    };
    const uniqueStatuses = objectData !== '' ? Array.from(new Set(objectData?.map(status => status?.mode))) : ""
    const processUnique = objectData !== '' ? Array.from(new Set(objectData?.map(status => status?.addedBy))) : ""

    const selecteTable = (currentPage, type, filterDataDsec, apiType) => {
        const start = moment(startDate).format('DD-MM-YYYY')
        const end = moment(endDate).format('DD-MM-YYYY')
        setLoader(true);
        if (type === "Expected") {
            getExpectedPaymentsListByDateApi(start, end, '', '', currentPage, itemsPerPage, filterDataDsec, (callback) => {
                console.log(callback)
                setLoader(false);
                if (callback && callback?.status == 200 && callback?.message == "success") {
                    setObjectData(callback?.data?.expectedList);
                    setFilteredData(callback?.data?.expectedList);
                    setTotalItems(callback?.data?.totalCounts)
                    scrollToBottom()
                } else {
                    setObjectData([]);
                    setFilteredData([]);
                    setTotalItems({})
                }
            })
        } else if (type === "Recieved") {
            getRecievedPaymentsListByDateApi(start, end, '', '', currentPage, itemsPerPage, filterDataDsec, (callback) => {
                console.log(callback)
                setLoader(false);
                if (callback && callback?.status == 200 && callback?.message == "success") {
                    setObjectData(callback?.data?.receivedPaymentsList);
                    setFilteredData(callback?.data?.receivedPaymentsList);
                    setTotalItems(callback?.data?.paymentCount)
                    scrollToBottom()
                } else {
                    setObjectData([]);
                    setFilteredData([]);
                    setTotalItems({})
                }
            })
        } else if (type === "OverDue" || type === "OnTime" || type === "Foreclose") {
            const bucketType = type === "OverDue" ? "overdue" : type === "OnTime" ? "onTime" : type === "Foreclose" ? "foreclose" : ''
            getRecievedPaymentsListByPaymentBucket(start, end, '', '', currentPage, itemsPerPage, filterDataDsec, bucketType, (callback) => {
                console.log(callback)
                setLoader(false);
                if (callback && callback?.status == 200 && callback?.message == "success") {
                    setObjectData(callback?.data?.receivedPaymentsList);
                    setFilteredData(callback?.data?.receivedPaymentsList);
                    setTotalItems(callback?.data?.paymentCount)
                    scrollToBottom()
                } else {
                    setObjectData([]);
                    setFilteredData([]);
                    setTotalItems({})
                }
            })
        }else if(type === "OverduestartDate" || type === "OverdueEndDate" ){
            const dueDate = type === "OverduestartDate" ? start : type === "OverdueEndDate" ? end : ''
            getOverdueDetailDataByDueDate(dueDate,'','',currentPage, itemsPerPage, filterDataDsec,(callback)=>{
                console.log(callback)
                setLoader(false);
                if (callback && callback?.status == 200 && callback?.message == "success") {
                    setObjectData(callback?.data?.overdueList);
                    setFilteredData(callback?.data?.overdueList);
                    setTotalItems(callback?.data?.overdueCount)
                    scrollToBottom()
                } else {
                    setObjectData([]);
                    setFilteredData([]);
                    setTotalItems({})
                }
            })
        }else{
            setLoader(false);
            setObjectData([]);
            setFilteredData([]);
            setTotalItems({})
        }

        if (apiType == "") {
            setCurrentPage(1)
            setTableView(tableView === type ? '' : type)
        } else {
            setTableView(type)
        }
    }

    const handleApply = (startDate, endDate) => {
        console.log(startDate, endDate)
        const start = moment(startDate).format('DD-MM-YYYY')
        const end = moment(endDate).format('DD-MM-YYYY')
        setLoader(true)
        getExpectedPaymentsStatsByDateApi(start, end, '', '', (callback) => {
            setLoader(false)
            if (callback?.status == 200 && callback?.message == "success") {
                setInitalValue(callback?.data)
                setObjectData([]);
                setFilteredData([]);
                setTotalItems({})
                setTableView('')
            } else {
                setInitalValue({})
                setObjectData([]);
                setFilteredData([]);
                setTotalItems({})
                setTableView('')
            }
        })
    }

    const handleAsecFilter = (type) => {
        setfilterDataDsec(type)
        setCurrentPage(1)
        selecteTable(1, tableView, type, "pagination")
    }

    const scrollToTop = () => {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToBottom = () => {
        setTimeout(() => {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 300)
    };




    return (
        <   div ref={topRef} className="container-fluid" style={{ paddingTop: '60px' }}>

            {popupState ?
                <PopUp closePopup={closePopup} popupStatus={popupStatus} /> : ""}
            {console.log(processType)}
            {loaderState ? <Loader /> : ""}
            <h3><b>All Payments</b></h3>

            <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-sm-2 col-xs-12">
                    <label>NBFC</label>
                    <select value={nbfc}
                        onChange={(e) => setNbfc(e.target.value)} className="form-control" >
                        <option value=''>All</option>
                        {
                            nbfclist && nbfclist?.length > 0 ?
                                nbfclist?.map((value, index) => {
                                    return (
                                        <option value={value?.nbfcId} key={index}>{value?.name}</option>
                                    )
                                })
                                : ''}
                    </select>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Start Date </label>
                    <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>End Date: </label>
                    <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <button className="btn" style={{ background: '#267dff', color: '#fff', marginTop: '22px', padding: '10px' }} onClick={() => handleApply(startDate, endDate)}>Apply Filter</button>
                </div>

            </div>
            {
                initalValue && Object.keys(initalValue)?.length > 0 ?
                    <TabsSystem selecteTable={selecteTable} startDate={startDate} endDate={endDate} nbfc={nbfc} initalValue={initalValue} />
                    : ''}
            {
                tableView !== '' ?
                    <div className="paymentInsightsMain" style={{ margin: '20px 0px 40px 0px' }} ref={bottomRef}>
                        <div className="paymentOverviewSection outerBoxWithPadding" style={{ padding: '5px 25px 25px 25px' }}>



                            {filteredData && filteredData?.length > 0 ? (
                                <div className="phocket-table-new">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h4 style={{ marginBottom: '20px', fontSize: '20px' }}>{handleHeadingText(tableView,startDate,endDate)} List</h4>
                                        <button className="btn" style={{ color: '#fff', background: '#267dff', width: '40px', height: '40px', fontSize: '20px' }} onClick={() => scrollToTop()}><KeyboardArrowUpIcon /></button>
                                    </div>
                                    <div style={{ overflowX: 'auto' }}>
                                        <table>
                                            <thead>
                                                {
                                                    tableView === "Expected" ?
                                                        <tr>
                                                            <th className="text-center">Application Id</th>
                                                            <th className="text-center">Name</th>
                                                            <th className="text-center">Principal Amount</th>
                                                            <th className="text-center">Interest Amount</th>
                                                            <th className="text-center">EMI Amount</th>
                                                            <th className="text-center" onClick={() => handleAsecFilter(filterDataDsec === "asc" ? "desc" : "asc")}>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    Due Date
                                                                    <div style={{ position: 'relative' }}>
                                                                        {
                                                                            filterDataDsec == "asc" ?
                                                                                <ArrowDropUpIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter("desc")} />
                                                                                :
                                                                                <ArrowDropDownIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter("asc")} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        : ''}
                                                {
                                                    tableView === "Recieved" || tableView === "OverDue" || tableView === "OnTime" || tableView === "Foreclose" ?
                                                        <tr>
                                                            <th className="text-center">Application Id</th>
                                                            <th className="text-center">Name</th>
                                                            <th className="text-center">OnToday Amount</th>
                                                            <th className="text-center">EMI Amount</th>
                                                            <th className="text-center">Overdue Amount</th>
                                                            <th className="text-center">Principal Amount</th>
                                                            <th className="text-center">Interest Amount</th>
                                                            <th className="text-center">Penalty</th>
                                                            <th className="text-center">Penalty GST</th>
                                                            <th className="text-center">Bounce</th>
                                                            <th className="text-center">Bounce GST</th>
                                                            <th className="text-center">Transaction Id</th>
                                                            <th className="text-center">Transaction Amount</th>
                                                            <th className="text-center" onClick={() => handleAsecFilter(filterDataDsec === "asc" ? "desc" : "asc")}>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    Paid Date
                                                                    <div style={{ position: 'relative' }}>
                                                                        {
                                                                            filterDataDsec == "asc" ?
                                                                                <ArrowDropUpIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter("desc")} />
                                                                                :
                                                                                <ArrowDropDownIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter("asc")} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        : ''}
                                                {
                                                    tableView === "OverduestartDate" || tableView === "OverdueEndDate" ?
                                                        <tr>
                                                            <th className="text-center">Application Id</th>
                                                            <th className="text-center">Name</th>
                                                            <th className="text-center">Overdue Amount</th>
                                                            <th className="text-center">Principal Amount</th>
                                                            <th className="text-center">Interest Amount</th>
                                                            <th className="text-center">Penalty</th>
                                                            <th className="text-center">Bounce Charges</th>
                                                            <th className="text-center">Overdue Days</th>
                                                            <th className="text-center" onClick={() => handleAsecFilter(filterDataDsec === "asc" ? "desc" : "asc")}>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                EMI Due Date
                                                                    <div style={{ position: 'relative' }}>
                                                                        {
                                                                            filterDataDsec == "asc" ?
                                                                                <ArrowDropUpIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter("desc")} />
                                                                                :
                                                                                <ArrowDropDownIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter("asc")} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        : ''}
                                            </thead>
                                            <tbody>
                                                {filteredData?.map((data, index) => (
                                                    <tr key={index}>
                                                        {
                                                            tableView === "Expected" ?
                                                                <>
                                                                    <td className="text-center">{data?.applicationId || "-"}</td>
                                                                    <td className="text-center">{data?.name || "-"}</td>
                                                                    <td className="text-center">{(data?.principal || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.interest || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.emiAmount || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center" style={{ minWidth: '110px' }}>{data?.dueDate || "-"}</td>
                                                                </> : ''}
                                                        {
                                                            tableView === "Recieved" || tableView === "OverDue" || tableView === "OnTime" || tableView === "Foreclose" ?
                                                                <>
                                                                    <td className="text-center">{data?.applicationId || "-"}</td>
                                                                    <td className="text-center" style={{ minWidth: '220px' }}>{data?.name || "-"}</td>
                                                                    <td className="text-center">{(data?.onTodayAmount || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.emiAmount || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.overdueAmount || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.principal || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.interest || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.penalty || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.penaltyGst || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.bounce || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.bounceGst || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{data?.txnId || "-"}</td>
                                                                    <td className="text-center">{(data?.txnAMount || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center" style={{ minWidth: '110px' }}>{data?.paidDate || "-"}</td>
                                                                </> : ''}
                                                        {
                                                            tableView === "OverduestartDate" || tableView === "OverdueEndDate" ?
                                                                <>
                                                                    <td className="text-center">{data?.applicationId || "-"}</td>
                                                                    <td className="text-center" style={{ minWidth: '220px' }}>{data?.debtorName || "-"}</td>
                                                                    <td className="text-center">{(data?.overdueAmount || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.principal || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.interest || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.penalty || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.bounceCharge || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center">{(data?.daysOverdue || '-')?.toLocaleString("en-US")}</td>
                                                                    <td className="text-center" style={{ minWidth: '110px' }}>{data?.emiDueDate || "-"}</td>
                                                                </> : ''}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ marginTop: '15px' }}>
                                        <Pagination
                                            count={totalItems}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            color="primary" style={{ display: 'flex', justifyContent: 'end' }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <p style={{ marginTop: '100px' }} className="text-center">
                                    <img src={NodataImage} style={{ width: '30%' }} />
                                </p>
                            )}
                        </div>
                    </div>
                    :
                    ''
            }

        </div>
    );
};

export default AllPaymentsNewTab;


const TabsSystem = ({ selecteTable, startDate, endDate, nbfc, initalValue }) => {
    const dropdownMapping = {
        Expected: { amount: 0 },
        Recieved: { amount: 0 },
        OverDue: { amount: 0 },
        OnTime: { amount: 0 },
        Foreclose: { amount: 0 },
        OverduestartDate: { amount: 0 },
        OverdueEndDate: { amount: 0 },
    
    
    };
    
    const mapping = {
        Expected: ["Recieved"],
        Recieved: ["OverDue", "OnTime", 'Foreclose'],
        OverDue: ["OverduestartDate","OverdueEndDate"],
    
    
    };

    const defaultWidth = window.innerWidth > 1200 ? '25%' : window.innerWidth > 1800 ? "20%" : "33%";
    const [cardWidths, setCardWidths] = useState({
        Expected: defaultWidth,
        Recieved: defaultWidth,
        OverDue:  defaultWidth,
        OnTime:   defaultWidth, 
        Foreclose: defaultWidth,
        OverduestartDate: defaultWidth,
        OverdueEndDate: defaultWidth,
    });

    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentValues, setCurrentValues] = useState({});
    const open = Boolean(anchorEl);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [loaderState, setLoader] = useState(false);

    
    const handleClick = (event, key) => {
        setSelectedKey(key);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(() => {
        console.log(initalValue)
        if (initalValue) {
            setSelectedValues({})
            const obj = { Expected: initalValue }
            setCurrentValues(obj)
            // setCardWidths({ Expected: defaultWidth })
        } else {
            // setCardWidths({})
            setCurrentValues({})
        }

    }, [initalValue])

    const handleSelection = (key, value) => {
        setSelectedValues((prev) => {
            const newValues = { ...prev };

            if (newValues[key] === value) {
                delete newValues[key];
            } else {
                newValues[key] = value;

                const keys = Object.keys(newValues);
                const currentIndex = keys.indexOf(key);
                if (currentIndex >= 0) {
                    keys.slice(currentIndex + 1).forEach((k) => {
                        delete newValues[k];
                    });
                }
            }
            console.log(newValues)
            updateCurrentValues(newValues);
            return newValues;
        });

       

        handleClose();
    };

    const updateCurrentValues = async (selectedValues) => {
        console.log("selectedValues", selectedValues)
        const start = moment(startDate).format('DD-MM-YYYY')
        const end = moment(endDate).format('DD-MM-YYYY')

        const prevValues = currentValues;
        let newCurrentValues = {};
        let currentKey = "Expected";

        for (const [key, value] of Object.entries(prevValues)) {

            newCurrentValues[key] = value;
            if (key === selectedValues[currentKey]) {
                break;
            }
            currentKey = key;
        }
        const nextKey = selectedValues[selectedKey];
        console.log(nextKey)
        if (nextKey === "Recieved") {
            setLoader(true)
            await getRecievedPaymentsStatsByDateApi(
                start,
                end,
                '',
                '', (callback) => {
                    setLoader(false)
                    console.log(callback)
                    if (callback && callback?.message == "success" && callback?.status == 200) {
                        newCurrentValues[nextKey] = callback?.data;
                    } else {
                        newCurrentValues[nextKey] = {}
                    }
                });

        }
        else if (nextKey === "OnTime" || nextKey === "OverDue" || nextKey === "Foreclose") {
            const bucketValue = nextKey === "OnTime" ? "onTime" : nextKey === "OverDue" ? "overdue" : nextKey === "Foreclose" ? "foreclose" : ''
            setLoader(true)
            await getRecievedPaymentsStatsByPaymentBucket(
                start,
                end,
                '',
                '', bucketValue, (callback) => {
                    setLoader(false)
                    console.log(callback)
                    if (callback && callback?.message == "success" && callback?.status == 200) {
                        newCurrentValues[nextKey] = callback?.data;
                    } else {
                        newCurrentValues[nextKey] = {}
                    }
                });
        }
        else if (nextKey === "OverduestartDate" || nextKey === "OverdueEndDate") {
            const dueDate = nextKey === "OverduestartDate" ? start : nextKey === "OverdueEndDate" ? end : ''
            setLoader(true)
            await getOverDueDetailsStatsByDueDate(
                dueDate,
                '',
                '', (callback) => {
                    setLoader(false)
                    console.log(callback)
                    if (callback && callback?.message == "success" && callback?.status == 200) {
                        newCurrentValues[nextKey] = callback?.data;
                    } else {
                        newCurrentValues[nextKey] = {}
                    }
                });
        }




        while (selectedValues[currentKey]) {
            const nextKey = selectedValues[currentKey];
            if (dropdownMapping[nextKey]) {
                newCurrentValues[nextKey] = dropdownMapping[nextKey];
                currentKey = nextKey;
            } else {
                break;
            }
        }
        console.log("newCurrentValues", newCurrentValues)

        setCurrentValues(newCurrentValues);
    };




    const handleViewTable = (d) => {
        selecteTable(1, d, "asc")
    }




    const startResizing = (key, e) => {
        e.preventDefault();
        const startX = e.clientX;
        const startWidth = parseFloat(cardWidths[key] || defaultWidth);

        const onMouseMove = (moveEvent) => {
        const newWidth = Math.max(
                15,
                startWidth + ((moveEvent.clientX - startX) / window.innerWidth) * 100 
              );
            setCardWidths(prevState => {
                const newWidths = { ...prevState, [key]: `${newWidth}%` };

                return newWidths;
            });
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };



    return (
        <>
            <div className="Allpayment" style={{ marginTop: '30px' }}>
                {loaderState ? <Loader /> : ""}
                {
                    currentValues && Object.keys(currentValues)?.length > 0 ? Object.entries(currentValues)?.map(([d, value], index) => {
                        return (
                            <div className="columnTabs" key={index} style={{ width: cardWidths[d], marginTop: '30px', maxWidth: window.innerWidth > 1200 ? '33%' : window.innerWidth > 1800 ? "25%" : "50%", minWidth: '15%'}}>
                                <div className="singleCardBack">
                                    <div className="topTabs">
                                        <div className="text">
                                            <h4>{handleHeadingText(d,startDate,endDate)}</h4>
                                            {
                                                mapping[d] && mapping[d]?.length > 0 ?
                                                <>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, d)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            {selectedKey === d &&
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    slotProps={{
                                                        paper: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: '20ch',
                                                            },
                                                        },
                                                    }}
                                                >

                                                    {mapping[d]?.map((option) => (

                                                        <MenuItem key={option} selected={option === selectedValues[d]} onClick={() => handleSelection(d, option)}>

                                                            {handleHeadingText(option,startDate,endDate)}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            }
                                            </>:''}
                                        </div>
                                        <button className="viewDetails" onClick={() => handleViewTable(d)}>View Details</button>
                                    </div>
                                    
                                    {
                                        d === "Expected" ?
                                            <div className="bottomTabs">
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Total Amount</h5>
                                                        <h4>{(value?.totalCounts || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.totalAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Principal Amount</h5>
                                                        <h4>{(value?.totalCounts || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.totalPrincipal || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Interest Amount</h5>
                                                        <h4>{(value?.totalCounts || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.totalInterest || 0)?.toLocaleString("en-US")}</h3>
                                                </div>

                                            </div>
                                            : ''}
                                    {
                                        d === "Recieved" ?
                                            <div className="bottomTabs">
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Amount</h5>
                                                        <h4>{(value?.paymentCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentAmt || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Principal Amount</h5>
                                                        <h4>{(value?.paymentPrincipleCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentPrincipleAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Interest Amount</h5>
                                                        <h4>{(value?.paymentInterestCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentInterestAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Penalty Amount</h5>
                                                        <h4>{(value?.paymentPenaltyCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentPenaltyAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Bounce Amount</h5>
                                                        <h4>{(value?.paymentBounceCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentBounceAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Bounce GST</h5>
                                                        <h4>{(value?.paymentBounceGstCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentBounceGstAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>NACH Waived</h5>
                                                        <h4>{(value?.nachWaivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.nachWaived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Penalty Waived</h5>
                                                        <h4>{(value?.penaltyWaivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.penaltyWaived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Total Overdue</h5>
                                                        <h4>{(value?.overdueCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.totalOverdueAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Total EMI Amount</h5>
                                                        {/* <h4>{(0)?.toLocaleString("en-US")}</h4> */}
                                                    </div>
                                                    <h3>{(value?.totalEmiAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>

                                            </div>
                                            : ''}
                                    {
                                        d === "OverDue" || d === "OnTime" || d === "Foreclose" ?
                                            <div className="bottomTabs">
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Payment Amount</h5>
                                                        <h4>{(value?.paymentCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentAmt || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Principal Amount</h5>
                                                        <h4>{(value?.paymentPrincipleCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentPrincipleAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Interest Amount</h5>
                                                        <h4>{(value?.paymentInterestCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentInterestAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Penalty Amount</h5>
                                                        <h4>{(value?.paymentPenaltyCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentPenaltyAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Bounce Amount</h5>
                                                        <h4>{(value?.paymentBounceCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentBounceAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Bounce GST </h5>
                                                        <h4>{(value?.paymentBounceGstCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.paymentBounceGstAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>NACH Waived </h5>
                                                        <h4>{(value?.nachWaivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.nachWaived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Penalty Waived </h5>
                                                        <h4>{(value?.penaltyWaivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.penaltyWaived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Total Overdue </h5>
                                                        <h4>{(value?.overdueCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.totalOverdueAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>EMI Amount </h5>
                                                        {/* <h4>{0}</h4> */}
                                                    </div>
                                                    <h3>{(value?.totalEmiAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>

                                            </div>
                                            : ''}
                                            {
                                        d === "OverduestartDate" || d === "OverdueEndDate" ?
                                            <div className="bottomTabs">
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Overdue Amount</h5>
                                                        <h4>{(value?.overdueCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.overDueAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Overdue Principal Amount</h5>
                                                        {/* <h4>{(value?.totalCounts || 0)?.toLocaleString("en-US")}</h4> */}
                                                    </div>
                                                    <h3>{(value?.overDuePrincipleAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Overdue Interest Amount</h5>
                                                        {/* <h4>{(value?.totalCounts || 0)?.toLocaleString("en-US")}</h4> */}
                                                    </div>
                                                    <h3>{(value?.overDueInterestAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Overdue Penalty</h5>
                                                        <h4>{(value?.penaltyCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.overDuePenaltyAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Overdue Bounce</h5>
                                                        <h4>{(value?.bounceCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.overDueBounceAmount || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Principal Waived</h5>
                                                        <h4>{(value?.principalWaivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.principalWaived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Interest Waived</h5>
                                                        <h4>{(value?.interestWaivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.interestWaived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>
                                                <div className="BottomSectionCards">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5>Received Amount</h5>
                                                        <h4>{(value?.receivedCount || 0)?.toLocaleString("en-US")}</h4>
                                                    </div>
                                                    <h3>{(value?.amountReceived || 0)?.toLocaleString("en-US")}</h3>
                                                </div>

                                            </div>
                                            : ''}
                                    <div
                                        className="rightResizer"
                                        onMouseDown={(e) => startResizing(d, e)}
                                        >
                                        <div className="imgss"><DragHandleIcon /></div>

                                    </div>
                                </div>

                            </div>
                        )
                    })
                        : ''}
            </div>
        </>
    )
}
