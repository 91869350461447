import React, { useEffect, useState } from "react";
import { emiOverdueListApi, getUserDetailByLoanIdOptimisedApi } from "../AdminActionCreator.component";
import Pagination from '@mui/material/Pagination';
import Loader from '../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../images/nodata.png';
import moment from "moment";
import { APIS } from "../../../../utils/api-factory";
import { monthStartDate, todayDate } from "../../Marketing/maketingComponents/utils/utils";
import { lastDayOfISOWeek } from "date-fns";

const OverDueEmi = ({ userDetailHandler }) => {
    const [processType, setProcessType] = useState('');
    const [objectData, setObjectData] = useState([]);
    const [loaderState, setLoader] = useState(false);
    const [popupStatus, setpopupStatus] = useState('No Data Found!');
    const [popupState, setpopupState] = useState(false);
    const [countData, setCounts] = useState('');
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [startDateToShow, setStartDateToShow] = useState(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDateToShow, setendDateToShow] = useState(moment(new Date()).format('YYYY-MM-DD'));

    const [currentPage, setCurrentPage] = useState(1);
    const [viewPage, setViewPage] = useState(false)
    const itemsPerPage = 15;

    useEffect(() => {
        setLoader(true);
        emiOverdueListApi(
            moment(startDate).format('DD-MM-YYYY'),
            moment(endDate).format('DD-MM-YYYY'),
            callback => {
                setObjectData(callback.data);
                setCounts(callback);
                setLoader(false);
            }
        );
    }, []);

    const filterData = () => {
        setLoader(true);
        emiOverdueListApi(
            moment(startDate).format('DD-MM-YYYY'),
            moment(endDate).format('DD-MM-YYYY'),
            callback => {
                setObjectData(callback.data);
                setCounts(callback);
                setLoader(false);
                setCurrentPage(1);
            }
        );
    };
    const handleDate = (value, type) => {
console.log(value)
        if (type === 'start') {
            setStartDate(value)
            setStartDateToShow(value)
        }
        if (type === 'end') {
            setEndDate(value)
            setendDateToShow(value)



        }
    }
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    // const userDetailHandler = (data) => {
    //     // this.setState({ mainLoaderState: true })
    //     if (data.loanId != '' && data.loanId != null) {
    //         getUserDetailByLoanIdOptimisedApi(data.loanId, data.userId,
    //             (callBack) => {
    //                 // this.setState({
    //                 //     userState: false,
    //                 //     viewState: true,
    //                 //     allDetail: callBack,
    //                 //     userDetail: data,
    //                 //     mainLoaderState: false
    //                 // })
    //             })
    //     }


    // }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = objectData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            {viewPage === false ?
                <div className="container-fluid">
                    {console.log(startDateToShow,endDateToShow)}
                    {loaderState ? <Loader /> : ""}
                    <h3><b>Overdue EMI's</b></h3>
                    <div className="row">
                        <div className="col-sm-2 col-xs-12">
                            <label>Start Date </label>
                            <input
                                type="date"
                                className="form-control"
                                value={startDateToShow}
                                onChange={(e) => handleDate(e.target.value, 'start')}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <label>End Date: </label>
                            <input
                                type="date"
                                className="form-control"
                                value={endDateToShow}
                                onChange={(e) => handleDate(e.target.value, 'end')}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <button className="btn btn-primary" style={{ marginTop: '22px', padding: '10px' }} onClick={filterData}>
                                Filter
                            </button>
                        </div>
                    </div>

                    {countData !== '' ?
                    <>
                        {/* <div className='row'>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '133px' }}>
                                    Total Amount <br />
                                    <h3 style={{ color: 'grey', fontWeight: '700' }}>
                                        <i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.total_amount).toFixed(2)).toLocaleString('en-IN')}
                                    </h3>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '133px' }}>
                                    Principal Amount <br />
                                    <h3 style={{ color: 'grey', fontWeight: '700' }}>
                                        <i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.total_principal_amount).toFixed(2)).toLocaleString('en-IN')}
                                    </h3>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '133px' }}>
                                    Interest Amount <br />
                                    <h3 style={{ color: 'grey', fontWeight: '700' }}>
                                        <i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.total_interest_amount).toFixed(2)).toLocaleString('en-IN')}
                                    </h3>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '133px' }}>
                                    Penalty Amount <br />
                                    <h3 style={{ color: 'grey', fontWeight: '700' }}>
                                        <i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.total_penalty_amount).toFixed(2)).toLocaleString('en-IN')}
                                    </h3>
                                </div>
                            </div>
                        </div> */}
                        <div className="paymentInsightsMain" style={{margin:'20px 0px 40px 0px'}}>
                        <div className="paymentOverviewSection outerBoxWithPadding">
                            <div className="heading" style={{display:'flex',justifyContent:'space-between'}}>
                                <h4>Overview</h4>
                                
                            </div>
                            <div className="PaymentBoxes">
                                <div className="tabs" style={{width:"25%"}}>
                                    <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d' }}><i class="fa fa-cart-arrow-down" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>

                                        </div>
                                        <h4>{'₹ ' + (Number(countData.total_amount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Total Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs" style={{width:"25%"}}>
                                    <div className="subtabs" style={{ backgroundColor: '#fff4de', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#ff947a' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>

                                        </div>
                                        <h4>{'₹ ' + (Number(countData.total_principal_amount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Principal Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs" style={{width:"25%"}}>
                                    <div className="subtabs" style={{ backgroundColor: '#fff1c1', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#dbb328' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>

                                        </div>
                                        <h4>{'₹ ' + (Number(countData.total_interest_amount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Interest Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs" style={{width:"25%"}}>
                                    <div className="subtabs" style={{ backgroundColor: '#dcfce7', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#3dd856' }}><i class="fa fa-credit-card-alt" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>
                                        </div>
                                        <h4>{'₹ ' + (Number(countData.total_penalty_amount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Penalty Amount</h5>
                                    </div>
                                </div>
                               
                            </div>


                        </div>
                    </div>
                        </>

                        : ""}

                    {objectData && objectData.length > 0 ? (
                        <div className="phocket-table-new">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Details</th>
                                        <th className="text-center">Application Id</th>
                                        <th className="text-center">Due Date</th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">Penalty</th>
                                        <th className="text-center">Principal</th>
                                        <th className="text-center">Interest</th>
                                        <th className="text-center">Bounce</th>
                                        <th className="text-center">Overdue Days</th>
                                        <th className="text-center">Processing Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((data, index) => (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <i className="fa fa-info" style={{ cursor: 'pointer' }} onClick={() => userDetailHandler(data)}></i>
                                            </td>
                                            <td className="text-center">{data?.applicationId}</td>
                                            <td className="text-center">{moment(data?.emi_due_date).format('DD-MM-YYYY')}</td>
                                            <td className="text-center">{(data?.amount)?.toLocaleString("en-US")}</td>
                                            <td className="text-center">{(data?.penalty)?.toLocaleString("en-US")}</td>
                                            <td className="text-center">{(data?.principal)?.toLocaleString("en-US")}</td>
                                            <td className="text-center">{(data?.interest)?.toLocaleString("en-US")}</td>
                                            <td className="text-center">{data?.bounce}</td>
                                            <td className="text-center">{data?.overdue_days}</td>
                                            <td className="text-center">{(data?.processing_fee)?.toLocaleString("en-US")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ float: 'right', marginTop: '20px' }}>
                                <Pagination
                                    count={Math.ceil(objectData.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </div>
                        </div>
                    ) : (
                        <p style={{ marginTop: '100px' }} className="text-center">
                            <img src={NodataImage} style={{ width: '30%' }} alt="No data available" />
                        </p>
                    )}
                </div>
                : ""}

        </>
    );
};

export default OverDueEmi;
