import react, { useState, useEffect } from 'react'
import { BarChart, Bar, Pie, PieChart, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import moment from 'moment';
import NodataImage from '../../../../../images/nodata.png';
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import { getPaymentInsightsDashboardapi } from '../../AdminActionCreator.component';


const handleDateRange = (filter, dateType) => {
    const today = moment();
    let sDate, eDate;

    if (filter === 'today') {
        eDate = today.format('DD-MM-YYYY');
        sDate = today.subtract(1, 'days').format('DD-MM-YYYY');
    } else if (filter === 'lastWeek') {
        eDate = today.format('DD-MM-YYYY');
        sDate = today.subtract(7, 'days').format('DD-MM-YYYY');
    } else if (filter === 'lastMonth') {
        eDate = today.format('DD-MM-YYYY');
        sDate = today.subtract(30, 'days').format('DD-MM-YYYY');
    } else if (filter === 'custom') {
        eDate = today.format('YYYY-MM-DD');
        sDate = today.subtract(1, 'days').format('YYYY-MM-DD');
    } else {
        sDate = '';
        eDate = '';
    }
    return dateType === 'fromDate' ? sDate : eDate;
}

const groupByDate = (data) => {
    return data.reduce((acc, current) => {
        if (!acc[current.date]) {
            acc[current.date] = [];
        }
        acc[current.date].push(current);
        return acc;
    }, {});
};

export default function PaymentInsightsMain() {
    const PieCOLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const [weeklyCollectionData, setWeeklyCollectionData] = useState([])
    const [paymentOverViewData, setpaymentOverViewData] = useState([])
    const [selectValue, setSelectValue] = useState('lastWeek')
    const [mandateInsightTab, setMandateInsightTab] = useState('RazorPay')
    const [APIData, setAPIDate] = useState({})
    const [MandateInsightSuccessData, setMandateInsightSuccessData] = useState([])
    const [MandateInsightFailureData, setMandateInsightFailureData] = useState([])
    const [paymentChannelInsightsData, setpaymentChannelInsightsData] = useState([])
    const [paymentChannelAggregatedInsightsData, setpaymentChannelAggregatedInsightsData] = useState([])
    const [paymentChannelInsightsGroupData, setpaymentChannelInsightsGroupData] = useState([])
    const [fromDate, setFromDate] = useState(handleDateRange('lastWeek', 'fromDate'))
    const [popup, setPopup] = useState(false)
    const [loader, setLoader] = useState(false)
    const [toDate, setToDate] = useState(handleDateRange('lastWeek', 'toDate'))

    const handleChange = (e, type) => {
        if (type === 'selectBox') {
            const selectedFilter = e.target.value;
            setSelectValue(selectedFilter);
            setFromDate(handleDateRange(selectedFilter, 'fromDate'));
            setToDate(handleDateRange(selectedFilter, 'toDate'));

        } else if (type === 'fromDate') {
            setFromDate(e.target.value);
        } else if (type === 'toDate') {
            setToDate(e.target.value);
        } else if (type === 'apply') {
            HandleAPI()
        }

    }

    useEffect(() => {
        HandleAPI()
    }, [selectValue])


    const HandleAPI = () => {
        let sDate, eDate;

        if (selectValue === 'custom') {
            const startDate = moment(fromDate);
            const endDate = moment(toDate);

            const diffMonths = endDate.diff(startDate, 'months');
            console.log(diffMonths)
            if (diffMonths >= 1) {
                setPopup(true)
                removePopup()
                return
                // sDate = handleDateRange('today', 'fromDate');
                // eDate = handleDateRange('today', 'toDate');
            } else {
                sDate = startDate.format('DD-MM-YYYY');
                eDate = endDate.format('DD-MM-YYYY');
            }
        } else {
            sDate = fromDate
            eDate = toDate
        }
        console.log(sDate, '-', eDate)
        setLoader(true)
        getPaymentInsightsDashboardapi(sDate, eDate, (callback) => {
            setLoader(false)
            console.log(callback.weeklyPaymentInsights)
            console.log("done")
            setWeeklyCollectionData(callback?.weeklyPaymentInsights || [])
            setpaymentOverViewData(callback?.paymentOverviewData)
            setpaymentChannelInsightsData(callback?.paymentChannelInsights || [])
            setpaymentChannelInsightsGroupData(groupByDate(callback?.paymentChannelInsights?.paymentChannelInsights) || [])
            setpaymentChannelAggregatedInsightsData(callback?.paymentChannelInsights?.paymentChannelInsightAggregateData || [])
            setAPIDate(callback)
            setMandateInsightSuccessData(callback.mandateInsightsRazorpaySuccess || [])
            setMandateInsightFailureData(callback.mandateInsightsRazorpayFailure || [])
        })
    }

    const closePopup = () => {
        setPopup(false)
    }
    const removePopup = () => {
        setTimeout(() => {
            setPopup(false)
        }, 5000)
    }

    const handleMandateInsight = (value) => {
        setMandateInsightTab(value === mandateInsightTab ? '' : value)
        if (value === 'RazorPay') {
            setMandateInsightSuccessData(APIData.mandateInsightsRazorpaySuccess || [])
            setMandateInsightFailureData(APIData.mandateInsightsRazorpayFailure || [])
        } else if (value === 'BillDesk') {
            setMandateInsightSuccessData(APIData.mandateInsightsBillDeskSuccess || [])
            setMandateInsightFailureData(APIData.mandateInsightsBillDeskFailure || [])

        } else {
            setMandateInsightSuccessData([])
            setMandateInsightFailureData([])
        }
    }

    const paymentOverviewDataReduceValue = paymentChannelAggregatedInsightsData.reduce((acc, curr) => {
        acc[curr.mode.toLowerCase()] = {
            totalAmount: curr.totalAmount,
            count: curr.count
        };
        return acc;
    }, {})
    return (
        <>
            <div className="paymentInsightsMain">
                {popup ?
                    <PopUp closePopup={closePopup} popupStatus={'Data can\'t be Fetched for More Than 1 Month.'} /> : ""}
                {loader ? <Loader /> : ""}
                <div className="container-fluid">
                    <div className="heading">
                        <h3>Payment Insights</h3>
                    </div>
                    <div className="filters">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12">
                                <div className="inputGrp">
                                    <label htmlFor='selectBox' className='labelTag'><i class="fa fa-filter" aria-hidden="true"></i> Filter</label>
                                    <select className='form-control' value={selectValue} id="selectBox" onChange={(e) => handleChange(e, 'selectBox')}>
                                        <option value="today">Today</option>
                                        <option value="lastWeek">Last Week</option>
                                        <option value="lastMonth">Last Month</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </div>
                            </div>
                            {
                                selectValue === 'custom' ?
                                    <>

                                        <div className="col-sm-2 col-xs-12">
                                            <div className="inputGrp">
                                                <label htmlFor='fromDate' className='labelTag'>From</label>
                                                <input className='form-control' type="date" value={fromDate} id="fromDate" onChange={(e) => handleChange(e, 'fromDate')} />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xs-12">
                                            <div className="inputGrp">
                                                <label htmlFor='toDate' className='labelTag'>To</label>
                                                <input className='form-control' type="date" value={toDate} id="toDate" onChange={(e) => handleChange(e, 'toDate')} max={handleDateRange('custom', 'toDate')} />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xs-12">
                                            <div className="inputGrp" style={{ marginTop: '24px' }}>
                                                <button className='applyBtn' onClick={(e) => handleChange(e, 'apply')}>Apply Filter</button>
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                        </div>
                    </div>
                    {
                        APIData ?
                            <>
                                <div className="paymentOverviewSection outerBoxWithPadding">
                                    <div className="heading">
                                        <h4>Payment Overview</h4>
                                    </div>
                                    <div className="PaymentBoxes">
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d' }}><i class="fa fa-cart-arrow-down" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverViewData?.receivableEmiCount || 0)?.toLocaleString("en-US")}</h5>

                                                </div>
                                                <h4>{'₹ ' + (paymentOverViewData?.receivableEmiSum || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Recievable EMI</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#fff4de', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#ff947a' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverViewData?.paymentReceivedCount || 0)?.toLocaleString("en-US")}</h5>

                                                </div>
                                                <h4>{'₹ ' + (paymentOverViewData?.paymentReceivedSum || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Collected Amount (Current Date)</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#fff1c1', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#dbb328' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverViewData?.paymentReceivedByDueDateCount || 0)?.toLocaleString("en-US")}</h5>

                                                </div>
                                                <h4>{'₹ ' + (paymentOverViewData?.paymentReceivedByDueDateSum || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Collected Amount (By Due Date)</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#dcfce7', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#3dd856' }}><i class="fa fa-credit-card-alt" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverViewData?.razorpayMandateCreatedCount || 0)?.toLocaleString("en-US")}</h5>
                                                </div>
                                                <h4>{'₹ ' + (paymentOverViewData?.razorpayMandateCreatedSum || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Mandate Created</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#f4e8ff', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#bf83ff' }}><i class="fa fa-credit-card" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverViewData?.razorpayMandateFailedCount || 0)?.toLocaleString("en-US")}</h5>
                                                </div>

                                                <h4>{'₹ ' + (paymentOverViewData?.razorpayMandateFailedSum || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Mandate Failed</h5>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                                {
                                    weeklyCollectionData && weeklyCollectionData?.length > 0 ?

                                        <div className="weeklyCollectionSection outerBoxWithPadding">
                                            <div className="heading">
                                                <h4>Weekly Collection</h4>
                                            </div>

                                            <div className="barChart">
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        width={500}
                                                        height={300}
                                                        data={weeklyCollectionData}
                                                        barSize={20}
                                                        margin={{
                                                            top: 5,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                                <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid stroke="none" />
                                                        <XAxis dataKey="date" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Bar dataKey="amount" fill="url(#colorPv)" radius={[3, 3, 3, 3]} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>
                                        : ''}

                                <div className="MandateInsights outerBoxWithPadding">
                                    <div className="heading">
                                        <h4>Mandate Insights(Payments Collected)</h4>
                                    </div>
                                    <div className="row" style={{ marginTop: '30px' }}>
                                        <div className="col-md-6 col-xs-12">
                                            <div className={`boxes ${mandateInsightTab === 'RazorPay' ? 'active' : ''} outerBoxWithPadding`} onClick={() => handleMandateInsight('RazorPay')}>
                                                <h5>RazorPay</h5>
                                                {/* <h4>₹ 1,00,000</h4> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12">
                                            <div className={`boxes ${mandateInsightTab === 'BillDesk' ? 'active' : ''} outerBoxWithPadding`} onClick={() => handleMandateInsight('BillDesk')}>
                                                <h5>BillDesk</h5>
                                                {/* <h4>₹ 1,00,000</h4> */}
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        mandateInsightTab !== '' ?
                                            <div className="row" style={{ marginTop: '30px' }}>
                                                {
                                                    MandateInsightSuccessData && MandateInsightSuccessData?.length > 0 ?
                                                        <div className={`${MandateInsightFailureData && MandateInsightFailureData?.length > 0 ? 'col-lg-6' : 'col-xs-12'}`}>
                                                            <div className="barchart outerBoxWithPadding">
                                                                <div className="heading">
                                                                    <h3 style={{ marginTop: '0px' }}>{mandateInsightTab} Success</h3>
                                                                </div>
                                                                <ResponsiveContainer width="100%" height="100%">
                                                                    <BarChart
                                                                        width={500}
                                                                        height={300}
                                                                        data={MandateInsightSuccessData}
                                                                        barSize={20}
                                                                        margin={{
                                                                            top: 20,
                                                                            right: 30,
                                                                            left: 20,
                                                                            bottom: 15,
                                                                        }}
                                                                    >
                                                                        <defs>
                                                                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                                                <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                                                <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                                            </linearGradient>
                                                                        </defs>
                                                                        <CartesianGrid stroke="none" />
                                                                        <XAxis dataKey="date" />
                                                                        <YAxis />
                                                                        <Tooltip />
                                                                        <Bar dataKey="totalAmount" fill="url(#colorPv)" radius={[3, 3, 3, 3]} />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                {
                                                    MandateInsightFailureData && MandateInsightFailureData?.length > 0 ?
                                                        <div className={`${MandateInsightSuccessData && MandateInsightSuccessData?.length > 0 ? 'col-lg-6' : 'col-xs-12'}`}>
                                                            <div className="barchart outerBoxWithPadding">
                                                                <div className="heading">
                                                                    <h3 style={{ marginTop: '0px' }}>{mandateInsightTab} Failure</h3>
                                                                </div>
                                                                <ResponsiveContainer width="100%" height="100%">
                                                                    <BarChart
                                                                        width={500}
                                                                        height={250}
                                                                        data={MandateInsightFailureData}
                                                                        barSize={20}
                                                                        margin={{
                                                                            top: 20,
                                                                            right: 30,
                                                                            left: 20,
                                                                            bottom: 10,
                                                                        }}
                                                                    >
                                                                        <defs>
                                                                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                                                <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                                                <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                                            </linearGradient>
                                                                        </defs>
                                                                        <CartesianGrid stroke="none" />
                                                                        <XAxis dataKey="date" />
                                                                        <YAxis />
                                                                        <Tooltip />
                                                                        <Bar dataKey="totalAmount" fill="url(#colorPv)" radius={[3, 3, 3, 3]} />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                        : ''}
                                            </div>
                                            :
                                            ""
                                    }
                                    {
                                        mandateInsightTab !== '' && MandateInsightSuccessData && MandateInsightSuccessData?.length == 0 && MandateInsightFailureData && MandateInsightFailureData?.length == 0 ?
                                            <div className="outerBoxWithPadding text-center" style={{ marginTop: '50px' }}>
                                                <img src={NodataImage} alt="No Data" />
                                            </div> : ''}
                                </div>

                                <div className="paymentOverviewSection outerBoxWithPadding" style={{ marginBottom: '20px' }}>
                                    <div className="heading">
                                        <h4>Payment Channel Insights</h4>
                                    </div>
                                    <div className="PaymentBoxes" style={{ marginTop: '20px' }}>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: '144px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d' }}><i class="fa fa-cc-visa" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverviewDataReduceValue?.razorpay?.count || 0)?.toLocaleString("en-US")}</h5>

                                                </div>
                                                <h4>{'₹ ' + (paymentOverviewDataReduceValue?.razorpay?.totalAmount || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>RazorPay</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#fff4de', height: '144px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#ff947a' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverviewDataReduceValue?.["net banking"]?.count || 0)?.toLocaleString("en-US")}</h5>

                                                </div>
                                                <h4>{'₹ ' + (paymentOverviewDataReduceValue?.["net banking"]?.totalAmount || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Net Banking</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#fff1c1', height: '144px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#dbb328' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverviewDataReduceValue?.cheque?.count || 0)?.toLocaleString("en-US")}</h5>

                                                </div>
                                                <h4>{'₹ ' + (paymentOverviewDataReduceValue?.cheque?.totalAmount || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Cheque</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#dcfce7', height: '144px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#3dd856' }}><i class="fa fa-credit-card-alt" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverviewDataReduceValue?.nach?.count || 0)?.toLocaleString("en-US")}</h5>
                                                </div>
                                                <h4>{'₹ ' + (paymentOverviewDataReduceValue?.nach?.totalAmount || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>Nach</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{ backgroundColor: '#f4e8ff', height: '144px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                    <div className="circlesIcon" style={{ backgroundColor: '#bf83ff' }}><i class="fa fa-credit-card" aria-hidden="true"></i></div>
                                                    <h5 className="bolder-font">{(paymentOverviewDataReduceValue?.upi?.count || 0)?.toLocaleString("en-US")}</h5>
                                                </div>

                                                <h4>{'₹ ' + (paymentOverviewDataReduceValue?.upi?.totalAmount || 0)?.toLocaleString("en-US")}</h4>

                                                <h5>UPI</h5>
                                            </div>

                                        </div>
                                    </div>
                                    {
                                        paymentChannelInsightsGroupData ?
                                            <div className="credit-table" style={{ marginTop: '50px' }}>
                                                <div className="phocket-table-new">
                                                    <table>
                                                        <thead style={{ background: 'rgb(185, 216, 249)', color: "rgb(0, 0, 0)", position: 'sticky', top: '0', overflowY: "auto" }}>
                                                            <tr className='thead-class'>
                                                                <th className="text-center">Date</th>
                                                                <th className="text-center">Payment Mode</th>
                                                                <th className="text-center">Total Amount</th>
                                                                <th className="text-center">Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                Object.keys(paymentChannelInsightsGroupData).map((date, dateIndex) => {
                                                                    const dateGroup = paymentChannelInsightsGroupData[date];
                                                                    const rowColor = dateIndex % 2 === 0 ? '#f7f9fe' : '#fff';
                                                                    return dateGroup.map((item, index) => (
                                                                        <tr key={index}>
                                                                            {
                                                                                index === 0 && (
                                                                                    <td rowSpan={dateGroup.length} className="DateTd text-center" style={{
                                                                                        backgroundColor: rowColor
                                                                                    }}>
                                                                                        {date}
                                                                                    </td>
                                                                                )
                                                                            }
                                                                            <td className="text-center" style={{ backgroundColor: rowColor }}>{item.paymentMode}</td>
                                                                            <td className="text-center" style={{ backgroundColor: rowColor }}>{(item.totalAmount)?.toLocaleString("en-US") || 0}</td>
                                                                            <td className="text-center" style={{ backgroundColor: rowColor }}>{(item.count)?.toLocaleString("en-US") || 0}</td>
                                                                        </tr>
                                                                    ));
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            : ''}
                                </div>
                            </> :
                            <div className="text-center" style={{ marginTop: '50px' }}>
                                <img src={NodataImage} alt="No Data" />
                            </div>
                    }
                </div>
            </div>
        </>
    )
}
