import React, { useEffect, useState } from "react";
import { getSavedSalaryTargetDataApi,deleteSavedSalaryTargetDataApi } from "../../targetactioncreator";
import moment from "moment";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../../images/nodata.png';
const EmpList = ({ editHandler,showButton,admin }) => {
    const [loader,setLoader] =useState(false)
    const [sidePopup, setPopup] = useState(false);  
    const [popupStatus, setPopupStatus] = useState('');
    const [employeeData, setEmpData] = useState('')


    const handlebtnclick = (type,data) => { 
        console.log(data)
        setLoader(true)
        deleteSavedSalaryTargetDataApi(data?.id,(callback)=>{
            console.log(callback)
            setLoader(false)
            if(callback.status == '200'){
                getEmployees()
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })   
    };
    
    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        getEmployees()
    }, [])

    const getEmployees =()=>{
        setLoader(true)
        getSavedSalaryTargetDataApi(admin.emailId,callback => {
            console.log(callback)
            setLoader(false)
            if(callback && callback?.status == 200){
                setEmpData(callback.data)
            }
        })
    }
    const closePopup = () => {
        setPopup(false);
    };
    return (
        <div className={`${window.innerWidth > 1800 ? 'container-fluid' : 'container'} Executor`}>
            {
                loader ?
                <Loader />
            :''}
             {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
        
            {employeeData !==''&& employeeData.length>0 ?
            <div className='' style={{ background: '#f2f2f2', padding: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px', overflow: 'auto', marginTop: '50px' }}>
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px' }}>
                        <thead>
                            <tr>
                                <th className="text-center">S.No.</th>
                                <th className="text-center">Department Name</th>
                                <th className="text-center">Salary Target</th>
                                <th className="text-center">Monthly/Yearly</th>
                               {showButton? <th>Edit</th>:""}
                               {showButton? <th>Delete</th>:""}
                            </tr>
                        </thead>
                        <tbody>
                            {employeeData!==''&&employeeData.length>0 ? employeeData.map((data, i) => {
                                return (
                                    <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                        <td className='text-center'  style={{width:'100px'}}>{i + 1}</td>
                                        <td className='text-center'  >{data?.departmentName}</td>
                                        <td className='text-center'  >{data?.salaryTarget}</td>
                                        <td className='text-center'  >{data?.targetBy}</td>

                                      {showButton?  <td>
                                            <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => editHandler(data)}></i>
                                        </td>:""}
                                       {showButton? <td>
                                            <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', data)}></i>
                                        </td>:""}
                                    </tr>
                                )
                            }):""}

                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="text-center" style={{marginTop: '50px'}}>
                <img src={NodataImage}/>
                </div>
            }
        </div>
    );
};

export default EmpList;
