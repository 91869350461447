import React, { useState, useEffect } from "react";
import { calculateEmiBreakApi, generateRazorPayLinkApi } from "../../containers/Admin/AdminActionCreator.component";
import Loader from '../../presentationals/Loader/Loader.component'
const GenerateLink = ({ openGenerateLink, emiDetail, userDetail, admin }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [calculatedData, setCalculatedData] = useState('');
    const [emailComm, setEmailComm] = useState(true);
    const [smsComm, setSmsComm] = useState(true);
    const [paymentData, setPaymentData] = useState({});
    const [expiryDate, setExpiryDate] = useState('');
    const [otherNumber, setOtherNumber] = useState('');
    const [unixDateTime, setUnixTimestamp] = useState('');
    const [emiData, setSlectedData] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [otherNumberOption, setOtherOption] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('');
    const [loaderState, setLoader] = useState(false)
    const [calculateButton, setCalculateButton] = useState(true)

    const [extendedData, setExtendedData] = useState(
        emiDetail.filter(item => item.STATUS !== 'Paid').map((item) => ({
            ...item,
            waivedNach: '0',
            waivedPenalty: '0',
        }))
    );


    const handleCheckboxChange = (index) => {
        setCalculateButton(true)
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(index)) {
                return prevSelectedRows.filter((i) => i !== index);
            } else {
                return [...prevSelectedRows, index];
            }
        });
    };

    const handleSelectAllChange = () => {
        setCalculateButton(true)

        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(emiDetail.map((_, i) => i));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (emiDetail && emiDetail.length > 0) {
            setSelectAll(selectedRows.length === emiDetail.length);
        }
    }, [selectedRows, emiDetail]);

    const handleWaivedNachChange = (index, value) => {
        const updatedData = [...extendedData];
        updatedData[index].waivedNach = value;
        setExtendedData(updatedData);
    };

    const handleWaivedPenaltyChange = (index, value) => {
        const updatedData = [...extendedData];
        updatedData[index].waivedPenalty = value;
        setExtendedData(updatedData);
    };

    const getSelectedRowsData = () => {
        return extendedData.filter((_, i) => selectedRows.includes(i));
    };

    const calculate = (selectedData) => {
        setSlectedData(selectedData)
        setLoader(true)
        calculateEmiBreakApi(selectedData, 'emi', (callback) => {
            console.log("Callback from API:", callback);
            setCalculatedData(callback);
            setLoader(false)
            setCalculateButton(false)


        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'numberType') {
            setPaymentData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        if (name === 'numberType') {
            if (value === 'primary') {
                setOtherOption(false)

                setPaymentData((prevData) => ({
                    ...prevData,
                    mobileNumber: userDetail.mobileNumber,
                }));
            }
            if (value === 'alternate') {
                setOtherOption(false)

                setPaymentData((prevData) => ({
                    ...prevData,
                    mobileNumber: userDetail.alternativeNo,
                }));
            }
            if (value === 'other') {
                setOtherOption(true)
            }


        }
    };

    const changeHandler = (e, type) => {
        if (type === 'EMAIL') {
            setEmailComm(e.target.checked);
        } else if (type === 'SMS') {
            setSmsComm(e.target.checked);
        } else if (type === 'otherNumber') {
            setOtherNumber(e.target.value);
            setPaymentData((prevData) => ({
                ...prevData,
                mobileNumber: e.target.value,
            }));
        } else if (type === 'expiryDateTime') {
            setExpiryDate(e.target.value);
            const selectedDateTime = e.target.value;
            setDateTime(selectedDateTime);

            // Convert selected date and time to Unix timestamp
            if (selectedDateTime) {
                const unixTimestamp = new Date(selectedDateTime).getTime() / 1000; // Convert to seconds
                setUnixTimestamp(unixTimestamp);
                console.log(unixTimestamp)
            }
        }
    };
    const extractEmiData = (data) => {
        console.log(data);
        return data.map(item => ({
            emiId: item?.emiId ?? null,
            principalAmount: item?.princpleAmt ?? null,
            interestAmount: item?.InterestAmount ?? null,
            penalty: item?.penalty ?? null,
            penaltyGst: item?.penaltyGst ?? null,
            nachBounce: item?.nachBounce ?? '0',
            bounceGst: item?.bounceGst ?? '0',
            emiAmount: item?.AMOUNT ?? null,
            overdueAmount: item?.OVERDUEAMOUNT ?? '0',
            penaltyWaived: item?.waivedPenalty ?? '0',
            nachBounceWaived: item?.waivedNach ?? '0'
        }));
    };


    const linkGenerate = () => {
        console.log(selectedRows)
        console.log(paymentData.mobileNumber)
        if (paymentData.mobileNumber) {
            setLoader(true)
            let data = {
                "loanId": userDetail.loanId,
                "userId": userDetail.userId,
                "amount": calculatedData.totalAmount,
                "emiList": extractEmiData(emiData),
                "empId": admin.emailId,
                "paymentType": paymentData.PaymentType,
                "sendSms": smsComm,
                "sendMail": emailComm,
                "nbfcId": "RTcTsJVH",
                "penaltyWaived": 0,
                "nachBounceWaived": 0,
                "expireBy": unixDateTime,
                "mobileNumber": paymentData.mobileNumber
            }
            console.log(data)
            generateRazorPayLinkApi(data, callback => {
                console.log(callback)
                if (callback.shortUrl) {
                    setPaymentUrl(callback.shortUrl)
                    setLoader(false)

                }
            })
        }



    }
    const handleCopy = () => {
        if (paymentUrl !== '') {
            navigator.clipboard.writeText(paymentUrl)
                .then(() => {
                    alert('Payment URL copied to clipboard!');
                })
                .catch((error) => {
                    console.error('Error copying to clipboard: ', error);
                });
        }
    };


    return (
        <div className="background-blur income">
            {console.log(userDetail, 'userDetail')}
            <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: "8%", width: "80%", maxHeight: '70%', overflow: 'auto' }}>
                {loaderState ?
                    <Loader />
                    : ""}
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <h5><b>Generate Payment Link</b></h5>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="text-right">
                            <i
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => openGenerateLink()}
                                className="fa fa-times"
                            ></i>
                        </div>
                    </div>
                </div>
                {console.log(extendedData)}
                <div className="row from-group">
                    {userDetail.loanEmi !== 1 && emiDetail != null && emiDetail !== "" ? (
                        <div className="col-xs-12 phocket-table-new" style={{ overflow: "auto",marginTop:'0px' }}>
                            <table className="text-center">
                                <thead>
                                    <tr>
                                        <th className="text-center">
                                            Select
                                            <br />
                                            Select All&nbsp;&nbsp;
                                            <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                                        </th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">Principle Amt</th>
                                        <th className="text-center">Interest Amount</th>
                                        <th className="text-center">Penalty</th>
                                        <th className="text-center">Overdue Amount</th>
                                        <th className="text-center">Waived NACH</th>
                                        <th className="text-center">Waived Penalty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {extendedData.length > 0
                                        ? extendedData.map((data, i) => {
                                            const isSelected = selectedRows.includes(i);
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            onChange={() => handleCheckboxChange(i)}
                                                        />
                                                    </td>
                                                    <td className="text-center">{data.AMOUNT}</td>
                                                    <td className="text-center">
                                                        {data.princpleAmt !== null && data.princpleAmt !== undefined
                                                            ? Number(data.princpleAmt).toFixed(2)
                                                            : ""}
                                                    </td>
                                                    <td className="text-center">
                                                        {data.InterestAmount !== null && data.InterestAmount !== undefined
                                                            ? Number(data.InterestAmount).toFixed(2)
                                                            : ""}
                                                    </td>
                                                    <td className="text-center">
                                                        {data.penalty !== null && data.penalty !== undefined
                                                            ? Number(data.penalty).toFixed(2)
                                                            : ""}
                                                    </td>
                                                    <td className="text-center">{data.OVERDUEAMOUNT}</td>
                                                    {isSelected ? (
                                                        <>
                                                            <td className="text-center">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={data.waivedNach}
                                                                    onChange={(e) => handleWaivedNachChange(i, e.target.value)}
                                                                    placeholder="Enter Waived NACH"
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={data.waivedPenalty}
                                                                    onChange={(e) => handleWaivedPenaltyChange(i, e.target.value)}
                                                                    placeholder="Enter Waived Penalty"
                                                                />
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td className="text-center"></td>
                                                            <td className="text-center"></td>
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        ""
                    )}

                    {calculateButton ?
                        <div style={{ marginLeft: '20px' }}>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    const selectedData = getSelectedRowsData();
                                    console.log("Selected Rows Data:", selectedData);
                                    calculate(selectedData);
                                }}
                                style={{ marginTop: '30px' }}
                            >Calculate
                            </button>
                        </div>
                        : ''}
                        </div>
                    {calculatedData !== '' && paymentUrl === '' ? (
                        <>
                            <div className="" >
                                <br/>
                                <h3 style={{ color: 'black', marginLeft: '20px' }}>Total Amount For Payment Link is <b>{calculatedData.totalAmount}</b></h3>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <label className=""> Type of Payment </label>
                                        <div className="" style={{ marginBottom: '15px', }}>
                                            <select
                                                style={{ width: '100%' }}
                                                value={paymentData.PaymentType}
                                                className="form-control "
                                                name='PaymentType'
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <option value=''>Select Type</option>
                                                <option value="partpayment">Part Payment</option>
                                                <option value="fullpayment">Full Payment</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <label className=""> Send To </label>
                                        <div className="" style={{ marginBottom: '15px', }}>
                                            <select
                                                style={{ width: '100%' }}
                                                value={paymentData.numberType}
                                                className="form-control "
                                                name='numberType'
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <option value=''>Select Type</option>
                                                <option value="primary">Primary Number</option>
                                                <option value="alternate">Alternate Number</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    {otherNumberOption ? (
                                        <div className="col-sm-4 col-xs-12">
                                            <label className=""> Other Number </label>
                                            <input value={otherNumber} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'otherNumber')} placeholder="Enter Other Number" />
                                        </div>
                                    ) : ""}

                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <label className=""> Select Expiry Date</label>
                                        <input value={expiryDate} type="datetime-local" className="form-control w-50" onChange={(e) => changeHandler(e, 'expiryDateTime')} />
                                    </div>
                                    <div className='col-sm-4 col-xs-12'>
                                        <h6>Send To Debtor by the mode of :</h6>
                                        <div className="" style={{ marginBottom: '15px', display: 'flex' }}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(e) => changeHandler(e, 'EMAIL')}
                                                    checked={emailComm}
                                                    id="emailCheckbox"
                                                />
                                                <label className="form-check-label" htmlFor="emailCheckbox">
                                                    EMAIL
                                                </label>
                                            </div>
                                            &nbsp; &nbsp;
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(e) => changeHandler(e, 'SMS')}
                                                    checked={smsComm}
                                                    id="smsCheckbox"
                                                />
                                                <label className="form-check-label" htmlFor="smsCheckbox">
                                                    SMS
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        {/* <button style={{ marginTop: '10px' }} onClick={() => linkGenerate()} className="btn btn-primary">Generate Link</button> */}
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <button style={{ marginTop: '10px' }} onClick={() => linkGenerate()} className="btn btn-primary">Generate Link</button>
                                    </div>
                                </div>
                            </div>

                        </>
                    ) : ""}

                    {paymentUrl !== '' ? (
                        <div>
                            <div style={{ background: 'green', width: 'fit-content', padding: '7px', borderRadius: '5px', color: '#fff' }}>{paymentUrl}</div>
                            <button style={{ marginTop: '10px' }} className="btn btn-primary" onClick={handleCopy}>Copy Payment URL&nbsp;&nbsp; <i className="fa fa-copy"></i></button>
                        </div>
                    ) : ""}

                </div>
            </div>
       
    );
};

export default GenerateLink;
