import React, { useEffect, useState } from "react";
import { getAllEmployeesAttendanceApi } from "../../targetactioncreator";
import NodataImage from '../../../../../../images/nodata.png';

const AllAttendence = () => {
    const [allData, setAllData] = useState('');
    const [expandedDepartment, setExpandedDepartment] = useState(null);

    // Fetch data from API on component mount
    useEffect(() => {
        getAllEmployeesAttendanceApi(callback => {
            setAllData(callback.data);
        });
    }, []);

    const toggleAccordion = (month) => {
        setExpandedDepartment(expandedDepartment === month ? null : month);
    };

    return (
        <div className="department-main" style={{marginTop:'5%'}}>
        <div className="container">
            {/* {allData && Object.keys(allData).map((month) => (
                <div key={month} className="month-table">
                    <h3>{month}</h3>
                    <div className="phocket-table-new">
                        <table>
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Present</th>
                                    <th>Absent</th>
                                    <th>Half Day</th>
                                    <th>Paid Leave</th>
                                    <th>Earn Leave</th>
                                    <th>Total Working Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData[month].map((attendance) => (
                                    <tr key={attendance.id}>
                                        <td>{attendance.employeeName}</td>
                                        <td>{attendance.present}</td>
                                        <td>{attendance.noAbsent}</td>
                                        <td>{attendance.noHalfDay}</td>
                                        <td>{attendance.noPaidLeave}</td>
                                        <td>{attendance.noEarnLeave}</td>
                                        <td>{attendance.totalWorkingDays || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))} */}
            {allData && Object.keys(allData).length > 0 ? (
                <div className="accords openaccord">
                    {
                        Object.keys(allData).map((month) => {
                            return (
                                <div key={month} className="accordion-item" style={{ background: '#f2f2f2', marginBottom: '10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    <div
                                        className="accordion-header"
                                        style={{ cursor: 'pointer', padding: '10px', background: 'rgb(219, 233, 255)', color: '#000', borderRadius: '5px' }}
                                        onClick={() => toggleAccordion(month)}
                                    >
                                        <div className="row">
                                            <div className="col-sm-11 col-xs-12">
                                                {/* <label style={{ color: 'grey' }}>Month</label> <br /><h4>{month || 'N/A'}</h4> */}
                                                <h4 style={{fontSize:'24px',marginTop:'14px '}}>{month || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-1 col-xs-12 text-right" style={{ fontSize: '40px' }}>
                                                <b>{expandedDepartment === month ? '-' : '+'}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ overflow: 'auto' }} className={`accordion-body ${expandedDepartment === month ? 'expanded' : ''}`}>

                                        <h5>Employee Attendence</h5>

                                        <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px', borderRadius: '16px', }}>
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Employee Name</th>
                                                        <th>Present</th>
                                                        <th>Absent</th>
                                                        <th>Half Day</th>
                                                        <th>Paid Leave</th>
                                                        <th>Earn Leave</th>
                                                        <th>Total Working Days</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData[month].map((attendance) => (
                                                        <tr key={attendance.id}>
                                                            <td>{attendance.employeeName}</td>
                                                            <td>{attendance.present}</td>
                                                            <td>{attendance.noAbsent}</td>
                                                            <td>{attendance.noHalfDay}</td>
                                                            <td>{attendance.noPaidLeave}</td>
                                                            <td>{attendance.noEarnLeave}</td>
                                                            <td>{attendance.totalWorkingDays || 'N/A'}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>
                            )
                        })

                    }
                </div>
            ) : (
                <div className="text-center" style={{ marginTop: '50px' }}>
                    <img src={NodataImage} alt="No Data" />
                </div>
            )}
            </div>
        </div>
    );
};

export default AllAttendence;
