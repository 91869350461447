import React, { useEffect, useState } from "react";
import Popup from '../../../../presentationals/Popup/Popup.component';
import { saveExpenseData, getAllDescription, getAllDepartmentListOld } from "../targetactioncreator";
import moment from "moment";
import Loader from '../../../../presentationals/Loader/Loader.component';

const defaultDate = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const EditExpence = ({ editData, admin }) => {
    const [loader, setLoader] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);

    const [employeeData, setEmployeeData] = useState({
        expenseAmount: '',
        updatedBy: "",
        expenseDescriptionId: '',
        expenseDate: defaultDate(),
        gstAmount: 0,
        isGSTInclusive: false,
        verified: false,
        varifiedBy: admin.emailId,
        spendingType: '',
        paid: false,
        departmentId: '',
        vendorName: '',
        expenseLedger: '',
        igst: '',  ////only this
        cgst: '',///mandatory same amount
        sgst: '', ///mandatory same amount,
        comment:'',
        transactionNumber:'',
        invoiceNumber:'',
        paymentMode:''


    });
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [descriptionData, setDescriptionData] = useState([])

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.expenseAmount) {
            formErrors.expenseAmount = "Expense Amount is required";
            isValid = false;
        }
        if (!employeeData.gstAmount && employeeData.isGSTInclusive) {
            formErrors.gstAmount = "GST Amount is required if GST is inclusive";
            isValid = false;
        }
        if (!employeeData.varifiedBy) {
            formErrors.varifiedBy = "Verified By is required";
            isValid = false;
        }
        if (!employeeData.expenseDescriptionId) {
            formErrors.expenseDescriptionId = "Description is required";
            isValid = false;
        }
        if (!employeeData.spendingType) {
            formErrors.spendingType = "Spending Type is required";
            isValid = false;
        }
        if (!employeeData.expenseDate) {
            formErrors.expenseDate = "Expense Date is required";
            isValid = false;
        }
        if (!employeeData.departmentId) {
            formErrors.departmentId = " Department is required";
            isValid = false;
        }
        if (!employeeData.vendorName) {
            formErrors.vendorName = " Vendor is required";
            isValid = false;
        }
        if (!employeeData.expenseLedger) {
            formErrors.expenseLedger = "Ledger is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // setLoader(true)
            const formattedDate = moment(employeeData.expenseDate).format('DD-MM-YYYY');
            const formattedData = { ...employeeData, expenseDate: formattedDate, addedBy: admin.emailId };
            console.log("Expense Data Saved", formattedData);

            saveExpenseData(formattedData, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        getDescriptionData()
        if (editData.id) {
            // setEmployeeData({
            //     expenseAmount: editData.expenseAmount || "",
            //     updatedBy: editData.updatedBy || "",
            //     gstAmount: editData.gstAmount || "",
            //     isGSTInclusive: editData.isGSTInclusive || false,
            //     verified: editData.verified || false,
            //     varifiedBy: editData.varifiedBy || '',
            //     id: editData.id || '',
            //     expenseDate: editData.expenseDate ? moment(editData.expenseDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : "",
            //     expenseDescriptionId: editData.expenseDescriptionId || '',
            //     spendingType: editData.spendingType || '',
            //     paid: editData.paid,
            //     vendorName: editData.vendorName,
            //     expenseLedger: editData.expenseLedger,

            // });
        } else {
            setEmployeeData({
                expenseAmount: '',
                updatedBy: "addedBy",
                gstAmount: 0,
                isGSTInclusive: false,
                verified: false,
                varifiedBy: admin.emailId,
                expenseDate: defaultDate(),
                expenseDescriptionId: '',
                spendingType: '',
                paid: false,
                vendorName: '',
                expenseLedger: '',
                igst: '',
                cgst: '',
                sgst: ''
            });

        }
    }, [editData]);

    const getDescriptionData = () => {
        setLoader(true)
        getAllDescription('notRequired', '', '', callback => {
            setLoader(false)
            if (callback && callback?.status == 200) {
                setDescriptionData(callback.data)
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    }
    useEffect(() => {
        getAllDepartmentListOld((callback) => {
            console.log(callback)
            if (callback && callback?.status == 200) {
                setDepartmentList(callback?.data)
            } else {
                setDepartmentList([])
            }
        })
    }, [])
    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader /> : ''}
            <div className="target-box">
                <h3>Add/Update Expense Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="expenseAmount"
                                        value={employeeData.expenseAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.expenseAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseAmount}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>GST Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="gstAmount"
                                        value={employeeData.gstAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.gstAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.gstAmount}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="expenseDate"
                                        value={employeeData.expenseDate}
                                        onChange={handleChange}
                                    />
                                    {errors.expenseDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseDate}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Verified By</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="varifiedBy"
                                        value={employeeData.varifiedBy}
                                        onChange={handleChange}
                                        readOnly

                                    />
                                    {errors.varifiedBy && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.varifiedBy}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="departmentId" value={employeeData.departmentId} onChange={handleChange}>
                                        <option value="">Select Department</option>
                                        {
                                            departmentList && departmentList?.length > 0 ?
                                                departmentList?.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value?.departmentId}>{value?.departmentName}</option>
                                                    )
                                                })
                                                : ''}
                                    </select>
                                    {errors.departmentId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.departmentId}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Vendor Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="vendorName"
                                        value={employeeData.vendorName}
                                        onChange={handleChange}


                                    />
                                    {errors.vendorName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.vendorName}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Ledger</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="expenseLedger"
                                        value={employeeData.expenseLedger}
                                        onChange={handleChange}


                                    />
                                    {errors.expenseLedger && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseLedger}</span>
                                    )}
                                </div>
                            </div>
                            {employeeData.igst === '' ?
                                <div style={{ display: 'flex', marginTop: '28px' }}>
                                    <div style={{ width: '50%' }}>
                                        <label style={{ marginTop: '10px' }}>cgst</label>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <input
                                            style={{ background: '#fff', border: '1px solid' }}
                                            className="form-control"
                                            type="text"
                                            name="cgst"
                                            value={employeeData.cgst}
                                            onChange={handleChange}


                                        />
                                        {/* {errors.igst && (
                                            <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.igst}</span>
                                        )} */}
                                    </div>
                                </div>
                                : ""}
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Head</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select name="expenseDescriptionId" onChange={handleChange} style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control" value={employeeData.expenseDescriptionId}>
                                        <option value=''>select Option</option>
                                        {
                                            descriptionData && descriptionData?.map((value, index) => {
                                                return (
                                                    <option value={value.expenseDescriptionId} key={index}>{value.expenseDescription}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors.expenseDescriptionId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseDescriptionId}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Spending Type</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select name="spendingType" onChange={handleChange} style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control" value={employeeData.spendingType}>
                                        <option value=''>select Option</option>
                                        <option value='technical'>Technical</option>
                                        <option value='operational'>Operational</option>

                                    </select>
                                    {errors.spendingType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.spendingType}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '25px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>GST Inclusive</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="isGSTInclusive"
                                        checked={employeeData.isGSTInclusive}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '25px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Verified</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="verified"
                                        checked={employeeData.verified}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '40px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Bill Paid?</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="paid"
                                        checked={employeeData.paid}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {employeeData.cgst === '' && employeeData.sgst === '' ?
                                <div style={{ display: 'flex', marginTop: '28px' }}>
                                    <div style={{ width: '50%' }}>
                                        <label style={{ marginTop: '10px' }}>IGST</label>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <input
                                            style={{ background: '#fff', border: '1px solid' }}
                                            className="form-control"
                                            type="text"
                                            name="igst"
                                            value={employeeData.igst}
                                            onChange={handleChange}


                                        />
                                        {errors.igst && (
                                            <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.igst}</span>
                                        )}
                                    </div>
                                </div>
                                : ""}
                            {employeeData.igst === '' ?
                                <div style={{ display: 'flex', marginTop: '28px' }}>
                                    <div style={{ width: '50%' }}>
                                        <label style={{ marginTop: '10px' }}>SGST</label>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <input
                                            style={{ background: '#fff', border: '1px solid' }}
                                            className="form-control"
                                            type="text"
                                            name="sgst"
                                            value={employeeData.sgst}
                                            onChange={handleChange}


                                        />
                                        {/* {errors.igst && (
                                            <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.igst}</span>
                                        )} */}
                                    </div>
                                </div>
                                : ""}
                           

                        </div>

                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary" >Save Expense Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditExpence;
