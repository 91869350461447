import React, { useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import OverviewSection from './OverviewSection';
import TransactionDta from './transaction';

export default function CategoryMain({ selectedData, startDate, endDate, handleBack ,admin}) {
    const [currentTab, setcurrentTab] = useState('overview')
    { console.log(selectedData) }
    return (
        <>
            <div className="categoryMain" style={{marginTop:'-1%'}}>
                <div className="row">
                    <div style={{ padding: '10px 5px', background: '#dfdbdb', borderBottom: '1px solid grey' }}>
                        <button style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }} onClick={() => handleBack()}><KeyboardBackspaceIcon />&nbsp; Back to All Categories</button>
                    </div>
                    <div className="leftSide">
                        <div className="tabs">
                            <div className={`tab ${currentTab === "overview" ? 'active' : ''}`} onClick={() => setcurrentTab('overview')}>
                                <h5>Overview</h5>
                                {
                                    currentTab === "overview" ?
                                        <div className='arrowbtn'>
                                            <PlayArrowIcon />
                                        </div>
                                        : ''}
                            </div>
                            <div className={`tab ${currentTab === "transaction" ? 'active' : ''}`} onClick={() => setcurrentTab('transaction')}>
                                <h5>Transactions</h5>
                                {
                                    currentTab === "transaction" ?
                                        <div className='arrowbtn'>
                                            <PlayArrowIcon />
                                        </div>
                                        : ''}
                            </div>
                        </div>
                    </div>
                    <div className="rightSide" style={{ width: 'calc(100%-150px)' }}>
                        {
                            currentTab === "overview" ?
                                <OverviewSection admin={admin} selectedData={selectedData} /> : ''
                        }
                        {currentTab === 'transaction' ?
                            <TransactionDta admin={admin} selectedData={selectedData}  />

                            : ""}
                    </div>
                </div>
            </div>
        </>
    )
}
