import React, { useEffect, useState } from "react";
import { saveEmpDataApi } from "../../../AdminActionCreator.component";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { saveEmployeeDataForTarget,getAllDepartmentList, getAllDepartmentListOld,getAllEmployeesForReportingApi } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';
import moment from "moment";

const defaultDate=()=>{
    const d = new Date();
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const day = String(d.getDate()).padStart(2, '0'); 

    return `${year}-${month}-${day}`;
}

const EmployeesTarget = ({ editData }) => {
    const [loader,setLoader] =useState(false);
    const [departmentlist ,setDepartmentList] =useState([])
    const [employeeData, setEmployeeData] = useState({
        employeeName: "",
        employeeMobileNo: "",
        employeeType: "",
        employeeEmailId: "",
        employeeDepartment: "",
        designation: "",
        joiningDate: defaultDate(),
        salary: 0,
        variable: 0,
        otherComponent: 0,
        isActive: false,
        inHandAmount: 0,
        taxDeducted: 0,
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [reportingManager ,setReportingManager] = useState([]) 

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.employeeName) {
            formErrors.employeeName = "Employee Name is required";
            isValid = false;
        }
        if (employeeData.employeeMobileNo?.length !== 10) {
            formErrors.employeeMobileNo = "Employee Type is required";
            isValid = false;
        }
        if (!employeeData.employeeType) {
            formErrors.employeeType = "Employee Type is required";
            isValid = false;
        }
        if (!employeeData.employeeDepartment) {
            formErrors.employeeDepartment = "Department is required";
            isValid = false;
        }
        if (!employeeData.joiningDate) {
            formErrors.joiningDate = "Joining Date is required";
            isValid = false;
        }
        if (!employeeData.employeeEmailId) {
            formErrors.employeeEmailId = "Salary must be greater than 0";
            isValid = false;
        }
        if (employeeData.salary <= 0) {
            formErrors.salary = "Salary must be greater than 0";
            isValid = false;
        }
        if (employeeData.inHandAmount <= 0) {
            formErrors.inHandAmount = "In-Hand Amount must be greater than 0";
            isValid = false;
        }
        if (!employeeData.designation) {
            formErrors.designation = "Designation is Required";
            isValid = false;
        }
        if (employeeData.taxDeducted < 0) {
            formErrors.taxDeducted = "Tax Deducted cannot be negative";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            console.log("Employee Data Saved", employeeData);
            const formattedDate = moment(employeeData.joiningDate).format('DD-MM-YYYY');
            const formattedData = { ...employeeData, joiningDate: formattedDate };
            saveEmployeeDataForTarget(formattedData, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };
    useEffect(() => {

        getAllEmployeesForReportingApi((callback)=>{
            console.log(callback)
            if(callback && callback?.status == 200 && callback?.message == "success"){
                setReportingManager(callback?.data)
            }else{
                setReportingManager([])
            }

        })

        getAllDepartmentListOld((callback)=>{
            console.log(callback)
            if(callback && callback?.status == 200){
                setDepartmentList(callback?.data)
            }else{
                setDepartmentList([])
            }
        })

        if (editData.id) {
            setEmployeeData({
                employeeName: editData.employeeName || "",
                employeeType: editData.employeeType || "",
                employeeDepartment: editData.employeeDepartment || "",
                joiningDate: editData.joiningDate ? moment(editData.joiningDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : "",
                salary: editData.salary || 0,
                variable: editData.variable || 0,
                otherComponent: editData.otherComponent || 0,
                isActive: editData.isActive || false,
                inHandAmount: editData.inHandAmount || 0,
                taxDeducted: editData.taxDeducted || 0,
                employeeID: editData.employeeID || '',
                designation: editData.designation || '',
                id: editData.id || '',
                employeeMobileNo: editData.employeeMobileNo || '',
                employeeEmailId:editData.employeeEmailId ||  "",


            });
        }
    }, [editData]);


    return (
        <div className="container">
            {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
                {
                    loader ? 
                    <Loader />
                    :''}
            <div className="target-box">
                <h3>Add/Update Employee Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Employee Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="employeeName"
                                        value={employeeData.employeeName}
                                        onChange={handleChange}
                                    />
                                    {errors.employeeName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeName}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Mobile Number</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="employeeMobileNo"
                                        value={employeeData.employeeMobileNo}
                                        onChange={handleChange}
                                    />
                                    {errors.employeeMobileNo && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeMobileNo}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Employee Type</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    {/* <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="employeeType"
                                        value={employeeData.employeeType}
                                        onChange={handleChange}
                                    /> */}
                                    <select style={{ background: '#fff', border: '1px solid' }} className="form-control"
                                        type="text"
                                        name="employeeType"
                                        value={employeeData.employeeType}
                                        onChange={handleChange}>
                                            <option>Select Employee Type</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Freelancer">Freelancer</option>
                                            <option value="Intern">Intern</option>
                                        </select>
                                    {errors.employeeType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeType}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    {/* <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="employeeDepartment"
                                        value={employeeData.employeeDepartment}
                                        onChange={handleChange}
                                    /> */}
                                    <select className="form-control" name="employeeDepartment" value={employeeData.employeeDepartment} onChange={handleChange}>
                                        <option value="">Select Department</option>
                                        {
                                            departmentlist && departmentlist?.length > 0 ?
                                            departmentlist?.map((value,index)=>{
                                                return(
                                                    <option key={index} value={value?.departmentId}>{value?.departmentName}</option>
                                                )
                                            })
                                       :'' }
                                    </select>
                                    {errors.employeeDepartment && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeDepartment}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Reporting Manager</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    {/* <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="reportingManager"
                                        value={employeeData.reportingManager}
                                        onChange={handleChange}
                                    /> */}
                                    <select className="form-control" name="reportingManager" value={employeeData.reportingManager} onChange={handleChange}>
                                    <option value="">Select Manager</option>
                                        {
                                            reportingManager && reportingManager?.length > 0 ?
                                            reportingManager?.map((value,index)=>{
                                                return(
                                                    <option key={index} value={value?.employeeEmailId}>{value?.employeeName}</option>
                                                )
                                            })
                                       :'' }
                                    </select>
                                    {errors.reportingManager && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.reportingManager}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Designation</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        id="customDateInput"
                                        type="text"
                                        name="designation"
                                        value={employeeData.designation}
                                        onChange={handleChange}
                                    />
                                    {errors.designation && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.designation}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Joining Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        id="customDateInput"
                                        type="date"
                                        name="joiningDate"
                                        value={employeeData.joiningDate}
                                        onChange={handleChange}
                                    />
                                    {errors.joiningDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.joiningDate}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Employee EmailId</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="email"
                                        name="employeeEmailId"
                                        value={employeeData.employeeEmailId}
                                        onChange={handleChange}
                                    />
                                    {errors.employeeEmailId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeEmailId}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Salary</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="salary"
                                        value={employeeData.salary}
                                        onChange={handleChange}
                                    />
                                    {errors.salary && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.salary}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Variable</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="variable"
                                        value={employeeData.variable}
                                        onChange={handleChange}
                                    />
                                    {errors.variable && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.variable}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Other Component</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="otherComponent"
                                        value={employeeData.otherComponent}
                                        onChange={handleChange}
                                    />
                                    {errors.otherComponent && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.otherComponent}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '15px' }}>In-Hand Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>

                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="inHandAmount"
                                        value={employeeData.inHandAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.inHandAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.inHandAmount}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '15px' }}>Tax Deducted</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="taxDeducted"
                                        value={employeeData.taxDeducted}
                                        onChange={handleChange}
                                    />
                                    {errors.taxDeducted && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.taxDeducted}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Is Active</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="isActive"
                                        checked={employeeData.isActive}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Employee Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmployeesTarget;
