import React, { useState } from "react";
import MainEmp from './Employee/mainEmp'
import MainDepartment from './Department/mainDepartment'
import MainTarget from './Targetssalary/mainTargets'
import AddTarget from "./Targetssalary/mainTargets";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EmployeesTarget from "./Employee/mainEmp";


const TabsExpense = ({ pageViewHandler, admin }) => {
    const [viewState, setViewState] = useState('add')
    const [editData, seteditData] = useState('')


    const BackBtnStyle2 = {
        color: '#fff',
        fontWeight: '400',
        display: 'flex',
        background: '#223d64',
        cursor: 'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }


    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '76px', display: 'flex', width: '100%',marginLeft:'0px' ,zIndex:'10' }}>
            
                <div className="" onClick={() => setViewState('add')} style={{ borderBottom: viewState === 'add' ? '2px solid #267DFF' : '', background: viewState === 'add' ? '#267DFF' : '#DBE9FF', padding: viewState === 'add' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'add' ? '#fff' : '#000', cursor: 'pointer',width:'33.33%' }}>
                    Employees

                </div>
                <div className="" onClick={() => setViewState('department')} style={{ borderBottom: viewState === 'department' ? '2px solid #267DFF' : '', background: viewState === 'department' ? '#267DFF' : '#DBE9FF', padding: viewState === 'department' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'department' ? '#fff' : '#000', cursor: 'pointer',width:'33.33%' }}>
                    Departments

                </div>
                <div className="" onClick={() => setViewState('target')} style={{ borderBottom: viewState === 'target' ? '2px solid #267DFF' : '', background: viewState === 'target' ? '#267DFF' : '#DBE9FF', padding: viewState === 'target' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'target' ? '#fff' : '#000', cursor: 'pointer',width:'33.33%' }}>
                    Targets

                </div>

            </div>

            <div className="" style={{ marginTop: window.innerWidth > 1800 ? '8.5%' :'10%'}}>

                {viewState === 'add' ?
                    <EmployeesTarget showButton={true} editData={editData} admin={admin} />
                    : ""}
                {viewState === 'department' ?
                    <MainDepartment  showButton={true} editData={editData} admin={admin}/>
                    : ""}
                {viewState === 'target' ?
                    <AddTarget  showButton={true} editData={editData} admin={admin}/>
                    : ""}
            </div>
        </>
    )
}
export default TabsExpense